import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import { FaCreativeCommons } from 'react-icons/fa'

const NotFoundPage = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "cat.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={ data => (
      <Layout>
        <SEO title="404: Page Not Found" />
        <h1>404 Ikke funnet</h1>
        <p>Denne adressen finnes ikke.</p>
        <p>Det <em>kan</em> skyldes at selve adressene har blitt forandret i forbindelse
          med en oppgradering. Prøv å gjøre et søk etter det du leter etter: (Denne søkeboksen
          bruker søkemotoren DuckDuckGo, som respekterer ditt personvern)
        </p>
        <iframe
          src="https://duckduckgo.com/search.html?site=www.indregard.no&prefill=Search..."
          title="DuckDuckGo Search box"
          style={ {overflow: "hidden", margin: 0, padding: 0, width: "408px", height: "40px"} }
          frameborder="0"></iframe>
        <p>Beklager det inntrufne! Her er en katt til trøst:</p>

        <figure>
          <Img alt="Foto av en katt" fluid={ data.file.childImageSharp.fluid }/>

          <figcaption>
            <a href="https://www.flickr.com/photos/114732443@N07/17273848281">
              «Bay cat»
            </a>, foto av Margot Guillet. Lisens:
            {" "}
            <a href="https://creativecommons.org/licenses/by-nc-nd/2.0/">
              <FaCreativeCommons/>
              {" "}
              Creative Commons Attribution-NonCommercial-NoDerivs 2.0
            </a>.
          </figcaption>
        </figure>

      </Layout>
    )} />
)

export default NotFoundPage
